import { createApp } from 'vue'
import { vfmPlugin } from 'vue-final-modal'
import VueTheMask from 'vue-the-mask'
import I18NextVue from 'i18next-vue'
import * as Sentry from '@sentry/vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import UIComponents from './plugins/ui-components'
import { capitalize } from './utils/text'

import './index.css'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(Toast)
  .use(VueTheMask)
  .use(vfmPlugin)
  .use(UIComponents)
  .use(I18NextVue, { i18next: i18n })

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.MODE,
    release: process.env.VUE_APP_GIT_TAG || 'unknown',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: process.env.VUE_APP_ENV === 'production',
      }),
    ],
  })
}

app.mixin({
  methods: {
    $c: capitalize,
  },
})

app.mount('#app')
